import React from 'react'

function Certificacion() {
  return (
    <div className='w-full flex justify-center items-center my-10 flex-col	' >
        <h2>we are certified by: </h2>
       <a href='https://www.gaf.com/en-ca/roofing-contractors/residential/crg-storm-contractor-llc-1135444' target='_blank'><img src='https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/resource%2Fheader_logotext.jpg?alt=media&token=1dbb5c23-4552-4918-a02c-81bf6a8bdc77' alt='certificacion' className='w-[200px]'/></a> 
    </div>
  )
}

export default Certificacion